"use client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple} from "@fortawesome/free-brands-svg-icons";
import {OAuthProvider, signInWithRedirect} from "firebase/auth";
import {auth} from "@/lib/firebaseConfig";

type CustomAppleSignInButtonProps = {
  onStartLoading?: () => void;
};

export default function CustomAppleSignInButton({onStartLoading}: CustomAppleSignInButtonProps) {
  const handleAppleSignIn = async () => {
    try {
      onStartLoading?.();
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.error("Google Redirect Sign-In Error:", error);
    }
  };

  return (
    <button
      type="button"
      onClick={handleAppleSignIn}
      className="inline-block px-6 py-2  bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl font-tungsten-semibold text-left uppercase hover:bg-black hover:text-primary transition-all duration-300">
      <FontAwesomeIcon icon={faApple} className="icon mr-3" size="sm" />
      <span>Sign in with Apple</span>
    </button>
  );
}
