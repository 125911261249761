/********************************************
 * 1) Password Rule Checking Logic (TypeScript)
 ********************************************/

interface PasswordCheckResult {
  hasLower: boolean;
  hasUpper: boolean;
  hasNumber: boolean;
  hasMinLength: boolean;
  noLeadingTrailingSpaces: boolean;
}

/**
 * Checks various password rules and returns booleans for each.
 */
export function checkPasswordRules(password: string): PasswordCheckResult {
  const hasLower = /[a-z]/.test(password);
  const hasUpper = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasMinLength = password.length >= 8;
  const noLeadingTrailingSpaces = password === password.trim() && password.length > 0;

  return {
    hasLower,
    hasUpper,
    hasNumber,
    hasMinLength,
    noLeadingTrailingSpaces
  };
}

/**
 * Returns `true` only if all password rules pass (including password match).
 */
export function allRulesPass(password: string, confirmPassword: string): boolean {
  const {hasLower, hasUpper, hasNumber, hasMinLength, noLeadingTrailingSpaces} = checkPasswordRules(password);
  const match = password === confirmPassword;

  return hasLower && hasUpper && hasNumber && hasMinLength && noLeadingTrailingSpaces && match;
}

/********************************************
 * 2) React Components (TypeScript + .tsx)
 ********************************************/

import React, {JSX} from "react";

interface PasswordRulesProps {
  password: string;
  confirmPassword: string;
}

/**
 * Shows each rule as ✓ (green) or ✗ (red).
 */
export function PasswordRules({password, confirmPassword}: PasswordRulesProps): JSX.Element {
  const {hasLower, hasUpper, hasNumber, hasMinLength, noLeadingTrailingSpaces} = checkPasswordRules(password);

  let passwordsMatch = false;
  if (password && confirmPassword) {
    passwordsMatch = password === confirmPassword;
  }

  return (
    <div className="space-y-1 text-sm">
      <RuleItem passed={hasLower && hasUpper} text="Must contain both a lowercase & an uppercase letter" />
      <RuleItem passed={hasNumber} text="Must contain a number" />
      <RuleItem passed={hasMinLength} text="Must be at least 8 characters long" />
      <RuleItem passed={noLeadingTrailingSpaces} text="Must not have leading or trailing spaces" />
      <RuleItem passed={passwordsMatch} text="Passwords must match" />
    </div>
  );
}

interface RuleItemProps {
  passed: boolean;
  text: string;
}

/**
 * Single rule line, showing check or X plus rule text.
 */
export function RuleItem({passed, text}: RuleItemProps): JSX.Element {
  return (
    <div className={`flex items-center gap-2 ${passed ? "text-green-600" : "text-red-600"}`}>
      <span className="font-bold">{passed ? "✓" : "✗"}</span>
      <span>{text}</span>
    </div>
  );
}
