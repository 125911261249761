"use client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {GoogleAuthProvider, signInWithRedirect} from "firebase/auth";

import {auth} from "@/lib/firebaseConfig";

type CustomGoogleSignInButtonProps = {
  onStartLoading?: () => void;
};

export default function CustomGoogleSignInButton({onStartLoading}: CustomGoogleSignInButtonProps) {
  const handleGoogleRedirect = async () => {
    try {
      onStartLoading?.();
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.error("Google Redirect Sign-In Error:", error);
    }
  };

  return (
    <button
      type="button"
      onClick={handleGoogleRedirect}
      className="inline-block px-6 py-2 
                 bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl font-tungsten-semibold  text-left
                 uppercase hover:bg-black hover:text-primary 
                 transition-all duration-300">
      <FontAwesomeIcon icon={faGoogle} className="icon mr-3" size="xs" />
      <span>Sign in with Google</span>
    </button>
  );
}
