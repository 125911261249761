"use client";
import {useState} from "react";
import {fetchSignInMethodsForEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, applyActionCode} from "firebase/auth";
import {auth} from "@/lib/firebaseConfig";
import {allRulesPass, PasswordRules} from "./PassowrdRules";
import {useRouter} from "next/navigation";
import {signIn} from "next-auth/react";

/**
 * State machine statuses: EmailPassAuthPage
 * 1) "ENTER_EMAIL" => user enters email, we check if user exists
 * 2) "ENTER_PASSWORD_LOGIN" => existing user: prompt for password, sign in
 * 3) "ENTER_PASSWORD_REGISTER" => new user: prompt for new password, create user
 * 4) "EMAIL_VERIFICATION_NEEDED" => user created but needs email verification
 * 5) "ENTER_VERIFICATION_CODE" => user wants to manually enter the code (if not using link)
 * 6) "SIGNED_IN" => user is fully signed in
 * 7) "ERROR" => any error
 */

type EmailPassAuthPageProps = {
  onStartLoading?: () => void;
  onRegister?: () => void;
};

export default function EmailPassAuthPage({onStartLoading, onRegister}: EmailPassAuthPageProps) {
  const [status, setStatus] = useState("ENTER_EMAIL");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const router = useRouter();

  // Keep track of whether user is logged in or verified:
  // useEffect(() => {
  //   // Listen for changes in Auth state
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     const handleAuthStateChange = async () => {
  //       if (user) {
  //         // Check if email is verified
  //         if (user.emailVerified) {
  //           setStatus("ENTER_EMAIL");
  //         } else {
  //           setStatus("EMAIL_VERIFICATION_NEEDED");
  //         }
  //       }
  //     };

  //     // Call the async function
  //     handleAuthStateChange();
  //   });

  //   // Cleanup the listener on component unmount
  //   return () => unsubscribe();
  // }, [router]);

  // Called when user enters an email to check if account exists
  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) return;
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);

      if (methods.length === 0) {
        // No user: register
        setStatus("ENTER_PASSWORD_REGISTER");
        onRegister?.();
      } else if (methods.includes("password")) {
        // Existing user with password
        setStatus("ENTER_PASSWORD_LOGIN");
      } else {
        // Possibly other providers, e.g. Google, etc.
        setStatus("ERROR");
      }
    } catch {
      setStatus("ERROR");
    }
  };

  // Called when an existing user tries to sign in with a password
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) return;
    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);
      if (!userCred.user.emailVerified) {
        setStatus("EMAIL_VERIFICATION_NEEDED");

        await sendEmailVerification(userCred.user);
      } else {
        const idToken = await userCred.user.getIdToken(true);
        document.cookie = `token=${idToken}; Path=/;`;

        // Send token to the server
        const res = await fetch("/api/updateUser", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({idToken})
        });

        if (!res.ok) {
          console.error("Failed to update user in DB");
        } else {
          // Redirect to dashboard or any protected route
          await signIn("firebase-credentials", {
            idToken,
            callbackUrl: "/profile"
          });
        }

        // Redirect to the homepage
        setStatus("ENTER_EMAIL");
        setPassword("");
        setVerificationCode("");
        onStartLoading?.();
        router.push("/dashboard");

        onStartLoading?.();
      }
    } catch {
      setStatus("ERROR");
    }
  };

  // Called when a new user is creating an account with a password
  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) return;
    try {
      const userCred = await createUserWithEmailAndPassword(auth, email, password);
      // Immediately send verification email
      await sendEmailVerification(userCred.user);
      setStatus("EMAIL_VERIFICATION_NEEDED");
    } catch {
      setStatus("ERROR");
    }
  };

  // If user wants to manually enter a code from the verification email
  const handleVerification = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!verificationCode) return;
    try {
      await applyActionCode(auth, verificationCode);

      // Now the onAuthStateChanged callback should re-check user.emailVerified
      // If not immediate, you can manually reload user or sign them in again
      // Force user reload:
      // await auth.currentUser.reload();
      // If you want to ensure status is updated right away:
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.emailVerified) {
        setStatus("SIGNED_IN");
      } else {
        setStatus("ENTER_EMAIL");
      }
    } catch {
      setStatus("ERROR");
    }
  };

  // Simple UI with conditionals:
  return (
    <div className="mx-auto text-white w-full">
      {/* Display a message if present */}
      {/* {message && <p className="text-sm mb-4">{message}</p>} */}

      {status === "ENTER_EMAIL" && (
        <form onSubmit={handleSubmitEmail} className="space-y-4 w-full">
          <div>
            <label className="block text-sm font-medium" htmlFor="email">
              Email
            </label>
            <input id="email" placeholder="name@host.com" type="email" className="border rounded w-full px-3 text-black py-2 mt-1" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <button
            className="w-full inline-block px-6 py-2 
                 bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl font-tungsten-semibold  text-center
                 uppercase hover:bg-black hover:text-primary 
                 transition-all duration-300">
            Continue
          </button>
        </form>
      )}

      {status === "ENTER_PASSWORD_LOGIN" && (
        <div>
          <div className="mb-6">
            <p>Account:</p>
            <p className="text-gray-500 mt-2 text-sm">{email}</p>
          </div>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label className="block text-sm font-medium" htmlFor="login-pass">
                Password
              </label>
              <input id="login-pass" placeholder="Passowrd" type="password" className="border rounded text-black w-full px-3 py-2 mt-1" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button
              className="w-full inline-block px-6 py-2 
                 bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl font-tungsten-semibold  text-center
                 uppercase hover:bg-black hover:text-primary 
                 transition-all duration-300">
              Sign In
            </button>
          </form>
        </div>
      )}

      {status === "ENTER_PASSWORD_REGISTER" && (
        <div>
          <div className="mb-6">
            <p>Create Account:</p>
            <p className="text-gray-500 mt-2 text-sm">{email}</p>
          </div>

          <form onSubmit={handleRegister} className="space-y-4">
            {/* CREATE PASSWORD */}
            <div>
              <label className="block text-sm font-medium" htmlFor="reg-pass">
                Create Password
              </label>
              <input id="reg-pass" placeholder="Passowrd" type="password" className="border text-black  rounded w-full px-3 py-2 mt-1" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>

            {/* CONFIRM PASSWORD */}
            <div>
              <label className="block text-sm font-medium" htmlFor="confirm-pass">
                Enter Password Again
              </label>
              <input
                id="confirm-pass"
                placeholder="Passowrd"
                type="password"
                className="border text-black rounded w-full px-3 py-2 mt-1"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {/* DYNAMIC RULE CHECKS */}
            <PasswordRules password={password} confirmPassword={confirmPassword} />

            {/* REGISTER BUTTON */}
            <button
              type="submit"
              disabled={!allRulesPass(password, confirmPassword)}
              className="
          w-full inline-block px-6 py-2 
          bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl
          font-tungsten-semibold text-center uppercase
          hover:bg-black hover:text-primary transition-all duration-300
          disabled:opacity-60
        ">
              Register
            </button>
          </form>
        </div>
      )}

      {status === "EMAIL_VERIFICATION_NEEDED" && (
        <div className="space-y-4">
          <p>Please check your email inbox for the verification link.</p>
          <button
            onClick={() => {
              setStatus("ENTER_EMAIL");
              setPassword("");
              setVerificationCode("");
            }}
            className="w-full inline-block px-6 py-2 
          bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl
          font-tungsten-semibold text-center uppercase
          hover:bg-black hover:text-primary transition-all duration-300
          disabled:opacity-6">
            Log In
          </button>
        </div>
      )}

      {status === "ENTER_VERIFICATION_CODE" && (
        <form onSubmit={handleVerification} className="space-y-4">
          <p>Paste the Firebase verification code from your email:</p>
          <div>
            <label className="block text-sm font-medium" htmlFor="verification-code">
              Verification Code
            </label>
            <input id="verification-code" type="text" className="border rounded w-full px-3 py-2 mt-1" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} required />
          </div>
          <button className="bg-orange-600 text-white px-4 py-2 rounded">Verify</button>
        </form>
      )}

      {status === "ERROR" && (
        <div className="space-y-4">
          <p className="text-white">An error occurred. Check the message above.</p>
          <button
            onClick={() => {
              setStatus("ENTER_EMAIL");

              setPassword("");
              setVerificationCode("");
            }}
            className="w-full inline-block px-6 py-2 
          bg-primary text-black font-bold text-2xl sm:text-2xl md:text-2xl
          font-tungsten-semibold text-center uppercase
          hover:bg-black hover:text-primary transition-all duration-300
          disabled:opacity-6">
            Restart
          </button>
        </div>
      )}
    </div>
  );
}
