"use client";
import React from "react";

export default function LoadingOverlay() {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="text-white text-xl font-bold">Loading...</div>
    </div>
  );
}
