"use client";
import React, {useEffect, useState} from "react";

import CustomGoogleSignInButton from "@/app/components/CustomGoogleSignInButton";
import CustomAppleSignInButton from "@/app/components/CustomAppleSignInButton";
import {getRedirectResult} from "firebase/auth";
import {auth} from "@/lib/firebaseConfig";
import {useRouter} from "next/navigation";
import LoadingOverlay from "../components/LoadingOverlay";
import CustomSignginEmailAndPassoword from "../components/CustomSignginEmailAndPassoword";
import {signIn} from "next-auth/react";
import axios from "axios";

export default function LoginPage() {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [hideLoginButtons, setHideLoginButtons] = useState(false);

  useEffect(() => {
    // 2) Finalize sign-in (if the user was redirected from Google/Apple sign-in)
    const finalizeSignIn = async () => {
      try {
        setIsLoading(true);
        const result = await getRedirectResult(auth);
        if (result) {
          // We have a signed-in user

          // Get the ID token
          const idToken = await result.user.getIdToken(true);
          document.cookie = `token=${idToken}; Path=/;`;

          // Optionally update the user in DB
          const res = await axios.post("/api/updateUser", {idToken});
          if (res.status !== 200) {
            console.error("Failed to update user in DB");
          } else {
            // Redirect to dashboard or any protected route
            await signIn("firebase-credentials", {
              idToken,
              callbackUrl: "/profile"
            });
          }
        }
      } catch (error) {
        console.error("Error finalizing sign-in:", error);
      } finally {
        // Regardless of success or error, we’re done checking
        setIsLoading(false);
      }
    };

    //
    finalizeSignIn();
  }, [router]);

  return (
    <div className="flex flex-col md:flex-row w-full h-screen items-center justify-center bg-gr-bl">
      {isLoading && <LoadingOverlay />}
      {/* LEFT PANEL */}
      <div className="w-full h-screen md:w-2/4 lg:w-3/5 xl:w-2/4 flex flex-col items-center justify-center p-8 px-12 pt-8 relative overflow-y-auto">
        {/* LOGO */}
        {/* TITLE + SUBHEADER */}
        <h2 className="text-lg text-white font-bold mb-2 text-center">Sign up or Login with</h2>
        <p className="text-xs text-gray-300 italic text-center">Join Now and Receive 100 Free MOZG Units!</p>

        {/* SOCIAL SIGN-IN BUTTONS */}
        <div className="w-full max-w-sm">
          {!hideLoginButtons && (
            <div className="flex flex-col space-y-2 gap-3 mb-8 mt-8 w-full">
              <CustomGoogleSignInButton onStartLoading={() => setIsLoading(true)} />
              <CustomAppleSignInButton onStartLoading={() => setIsLoading(true)} />
            </div>
          )}

          {!hideLoginButtons && (
            <div className="flex items-center justify-center">
              <span className="mx-2 text-gray-300">OR</span>
            </div>
          )}

          {!hideLoginButtons ? <div className="mt-2"></div> : <div className="mt-8"></div>}

          <CustomSignginEmailAndPassoword onStartLoading={() => setIsLoading(true)} onRegister={() => setHideLoginButtons(true)} />
        </div>
      </div>
    </div>
  );
}
